/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------
@mixin transition($property) {
  transition: $property cubic-bezier(0,.79,.48,1) 0.3s;
}

//
// General
// --------------------------------------------------
body{
  font-weight: 300;
}

//
// Typography
// --------------------------------------------------
h2,h3,h4{
  color: $brand-primary;
  small{
    font-size: 50%;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 0 .7em 0;
  }
  &.first-header{
    margin-top: 0;
  }
}
h2{
  font-size: $font-size-h2*0.6;
  @media(min-width: $screen-xs-min){
    font-size: $font-size-h2*0.8;
  }
  @media(min-width: $screen-sm-min){
    font-size: $font-size-h2;
  }
  @media(min-width: $screen-md-min){
    font-size: $font-size-h2*1.2;
  }
  .tab-content &{
    @media(min-width: $screen-md-min){
      font-size: $font-size-h2;
    }
  }
}
h3{
  font-size: $font-size-h3;
  @media(min-width: $screen-sm-min){
    font-size: $font-size-h3;
  }
  @media(min-width: $screen-md-min){
    font-size: $font-size-h3*1.2;
  }
}
.list-item-number{
  color: $brand-primary;
  width: 2.5em;
  height: 2.5em;
  display: inline-block;
  border: 2px solid $brand-primary;
  border-radius: 50%;
  text-align: center;
  line-height: 2em;
}

//
// Buttons
// --------------------------------------------------

// Common styles
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {


  // Reset the shadow
  &:active,
  &.active {
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
  }

  .badge {
    text-shadow: none;
  }
}

// Mixin for generating new styles
@mixin btn-styles($btn-color: #555) {
  background: rgba($btn-color, .8);
  border-color: rgba($btn-color, 1);
  background-repeat: repeat-x;
  padding: .6em 2em 0.8em;
  transition: all cubic-bezier(0,.79,.48,1) 0.3s;
  &:hover,
  &:focus  {
    background: rgba($btn-color, 1);
    border-color: rgba($btn-color, 1);
  }
  &:active,
  &.active {
    background: rgba($btn-color, 1);
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken($btn-color, 12%);
      background-image: none;
    }
  }
}

// Common styles
.btn {
  // Remove the gradient for the pressed/active state
  &:active,
  &.active {
    background-image: none;
  }
}

// Apply the mixin to the buttons
.btn-default {
  @include btn-styles(#FFF);
  color: #FFF;
  background: rgba(255,255,255,0.15);
  border: 1px solid rgba(255,255,255,.8);
  &:hover{
    background: rgba(255,255,255,.9);
    border: 1px solid rgba(255,255,255,1);
  }
  &.btn-inverted{
    @include btn-styles($gray-dark);
    background: rgba($gray-dark,0);
    color: rgba($gray-dark,.7);
    border: 1px solid rgba($gray-dark,.3);
    &:hover{
      color: rgba($gray-dark,1);
      border: 1px solid rgba($gray-dark,.5);
      background: rgba($gray-dark,0);
    }
  }
}
.btn-primary { @include btn-styles($btn-primary-bg); }
.btn-success { @include btn-styles($btn-success-bg); }
.btn-info    { @include btn-styles($btn-info-bg); }
.btn-warning { @include btn-styles($btn-warning-bg); }
.btn-danger  { @include btn-styles($btn-danger-bg); }


//
// Container
// --------------------------------------------------
.content-section{
  padding: 2em 0 2.5em;
  @media (min-width: $screen-md-min) {
    padding: 6em 0 6.5em;
  }
}
.container,
.container-fluid{
  &.bg-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.bg-key{
      background-image: url(../img/bg-keyvisual.jpg);
    }
  }
  &.bg-grey{
    background: $gray-lighter;
  }
  &.content-light{
    color: #FFF;
    & h2{
      color: #FFF;
    }
  }
}
.valign{
  @media (min-width: $grid-float-breakpoint){
    display: flex;
    align-items: center;
  }
}



//
// Helper
// --------------------------------------------------
.block-element{
  display: block;
}
.img-responsive{
  display: inline-block;
}
.illustration-primary{
  height: 3em;
  width: auto;
}

//
// Jumbotron
// --------------------------------------------------
.jumbotron{
  padding: 25vh 0 7vh;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  @media (min-width: $screen-xs-min){
    text-align: left;
    padding: 25vh 0;
    background-position: center;
  }
  h2{
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
    color: #FFF;
    margin-bottom: 1em;
  }
}


//
// Tabs
// --------------------------------------------------
.nav-pills-bubbles{
  &.nav-justified{
    width: auto;
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
    li {
      background: lighten($brand-primary, 50%);
      @include transition(all);
      a{
        text-align: left;
        font-weight: 400;
        color: $brand-primary;
        background: transparent;
        border-radius: 0;
        padding: 2em 2.5em 2.1em;
      }
      &:hover{
        background: lighten($brand-primary, 45%);
      }
      &:after{
        top: 60%;
        @include transition(all);
      }
      &.active{
        background: rgba($brand-primary,1);
        @media (min-width: $grid-float-breakpoint){
          &:after{
            top: 100%;
          	left: 80%;
          	border: solid transparent;
          	content: " ";
          	height: 0;
          	width: 0;
          	position: absolute;
          	pointer-events: none;
          	border-color: rgba($brand-primary,0);
          	border-top-color: $brand-primary;
          	border-width: 50px;
            border-right-width: 0;
          	margin-left: -50px;
          }
        }
      }
      &.active a{
        color: #FFF;
        background: transparent;
      }
    }
  }
}

//
// Slider
// --------------------------------------------------
.content-slider{
  &.slider-mockup{
    position: relative;
    @media (min-width: $screen-md-min) {
      padding: 2.2em 8.9em 3.2em;
      &:after{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: ' ';
        background-image:url(../img/laptop_mockup.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
    @media (min-width: $screen-lg-min) {
      padding: 3em 8.9em 4.2em;
    }
  }
  & .content-slider-wrap{
    margin: 0;
    padding: 0;
    list-style: none;
  }
  & .slider-wrapper,
  & .flex-viewport{
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .slider-panel {
    color: #FFF;
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    transition: height 0.3s ease 0s;
    img{
      width: 100%;
      height: auto;
    }
  }
  .flex-direction-nav{
    color: #FFF;
    position: absolute;
    top: 50%;
    z-index: 100;
    list-style: none;
    margin: 0;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    width: 100%;
    transition: all 0.2s ease 0s;
    visibility: visible;
    a{
      display: block;
      position: relative;
      text-align: center;
      display: block;
      outline: none;
      width: 2em;
      height: 2em;
      line-height: 2.5em;
      border-radius: 50%;
      border: 2px solid $brand-primary;
    }
    .flex-nav-prev{
      cursor: pointer;
      display: inline-block;
    }
    .flex-nav-next{
      float: right;
      cursor: pointer;
      display: inline-block;
    }
  }
}
/* Slider Nav */
ol.flex-control-nav{
	margin: 0 auto;
	list-style: none;
	padding: 0;
	position: absolute;
	bottom: -5em;
	left: 10%;
	right: 10%;
  z-index: 1000;
	text-align: center;
  li{
  	display: inline-block;
  	margin: 0 0em;
    a{
    	cursor: pointer;
    	display: inline-block;
    	color: transparent;
    	padding: 0.3em 0.5em;
    	text-align: center;
    	transition: all 0.2s ease 0s;
      &:before{
        content: ' ';
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 2px solid rgba($gray-base,.2);
        background: rgba($gray-base,.1);
        border-radius: 50%;
        transition: all 0.2s ease 0s;
      }
    }
  }
}
ol.flex-control-nav li a:hover:before,
ol.flex-control-nav li a.flex-active:before{
		background: rgba($brand-primary,.5);
    border-color: $brand-primary;
}



//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
  @include box-shadow(0 1px 2px rgba(0,0,0,.075));
}


//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  @include gradient-vertical($start-color: $dropdown-link-hover-bg, $end-color: darken($dropdown-link-hover-bg, 5%));
  background-color: darken($dropdown-link-hover-bg, 5%);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  @include gradient-vertical($start-color: $dropdown-link-active-bg, $end-color: darken($dropdown-link-active-bg, 5%));
  background-color: darken($dropdown-link-active-bg, 5%);
}


//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
  border: none;
  background: rgba($navbar-default-bg,1);
  color: $gray-dark;
  @include transition(all);
  a{
    background: none !important;
  }
  &.transparent{
    background: rgba($navbar-default-bg,0);
    .navbar-brand{
      .logo-grey{
        fill: #FFF;
      }
      .logo-color{
        fill: #FFF;
      }
    }
    .navbar-nav > li > a{
      color: #FFF;
    }
    @media (min-width: $screen-md-min) {
      padding: 2.5em 0 0.8em;
    }
  }
  @media (min-width: $screen-md-min) {
    padding: 0.8em 0;
  }
  .navbar-nav{
    .active>a, .active>a:hover{
      color: $brand-primary;
      background: none;
    }
  }
  .navbar-brand{
    @media (min-width: $screen-md-min) {
      width: 16em;
      padding: 10px 15px;
      height: auto;
    }
    .logo-grey{
      fill: #727272;
    }
    .logo-color{
      fill: #00B9E6;
    }
    svg{
      width: 100%;
      height: auto;
    }
  }
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    background: none;
    color: $brand-primary;
  }
}
.navbar-brand,
.navbar-nav > li > a {
  text-transform: uppercase;
  font-weight: 400;
  padding-top: 16px;
  padding-bottom: 17px;
  &:hover{
    color: $brand-primary;
    background-color: transparent;
  }
}


// Inverted navbar
.navbar-inverse {
  @include gradient-vertical($start-color: lighten($navbar-inverse-bg, 10%), $end-color: $navbar-inverse-bg);
  @include reset-filter; // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: $navbar-border-radius;
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    @include gradient-vertical($start-color: $navbar-inverse-link-active-bg, $end-color: lighten($navbar-inverse-link-active-bg, 2.5%));
    @include box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }
  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

// Fix active state of dropdown items in collapsed mode
@media (max-width: $grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      @include gradient-vertical($start-color: $dropdown-link-active-bg, $end-color: darken($dropdown-link-active-bg, 5%));
    }
  }
}


//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  $shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  @include box-shadow($shadow);
}

// Mixin for generating new styles
@mixin alert-styles($color) {
  @include gradient-vertical($start-color: $color, $end-color: darken($color, 7.5%));
  border-color: darken($color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { @include alert-styles($alert-success-bg); }
.alert-info       { @include alert-styles($alert-info-bg); }
.alert-warning    { @include alert-styles($alert-warning-bg); }
.alert-danger     { @include alert-styles($alert-danger-bg); }


//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  @include gradient-vertical($start-color: darken($progress-bg, 4%), $end-color: $progress-bg)
}

// Mixin for generating new styles
@mixin progress-bar-styles($color) {
  @include gradient-vertical($start-color: $color, $end-color: darken($color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { @include progress-bar-styles($progress-bar-bg); }
.progress-bar-success    { @include progress-bar-styles($progress-bar-success-bg); }
.progress-bar-info       { @include progress-bar-styles($progress-bar-info-bg); }
.progress-bar-warning    { @include progress-bar-styles($progress-bar-warning-bg); }
.progress-bar-danger     { @include progress-bar-styles($progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  @include gradient-striped;
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: $border-radius-base;
  @include box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken($list-group-active-bg, 10%);
  @include gradient-vertical($start-color: $list-group-active-bg, $end-color: darken($list-group-active-bg, 7.5%));
  border-color: darken($list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  @include box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
@mixin panel-heading-styles($color) {
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   {
  @include panel-heading-styles($panel-default-heading-bg);
  border: none;
}
.panel-primary > .panel-heading   { @include panel-heading-styles($panel-primary-heading-bg); }
.panel-success > .panel-heading   { @include panel-heading-styles($panel-success-heading-bg); }
.panel-info > .panel-heading      { @include panel-heading-styles($panel-info-heading-bg); }
.panel-warning > .panel-heading   { @include panel-heading-styles($panel-warning-heading-bg); }
.panel-danger > .panel-heading    { @include panel-heading-styles($panel-danger-heading-bg); }


//
// Pricing
// --------------------------------------------------

//
// Register Steps
// --------------------------------------------------
.register-steps{
  margin: 2em 0;
  dd{
    margin-bottom: 1em;
  }
}



//
// Footer
// --------------------------------------------------
.page-footer{
  padding: 2em 0;
  background: $gray-darker;
  color: $gray-light;
}

//
// Wells
// --------------------------------------------------

.well {
  @include gradient-vertical($start-color: darken($well-bg, 5%), $end-color: $well-bg);
  border-color: darken($well-bg, 10%);
  $shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  @include box-shadow($shadow);
}
